import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Layout, { ImageRow, Li } from '../templates/andyfischer-dev';
import Carousel from 'react-images';
export const _frontmatter = {
  "title": "Andrew Fischer CV"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Things I worked on`}</h1>
    <i>(Last updated: June 2020)</i>
    <h2><a parentName="h2" {...{
        "href": "https://www.shutterfly.com"
      }}>{`Shutterfly`}</a>{` (2012 - current)`}</h2>
    <ul>
      <li parentName="ul">{`Technical lead in frontend app development and infrastructure.`}</li>
      <li parentName="ul">{`Created a CI/CD build and deployment pipeline for frontend apps.`}</li>
      <li parentName="ul">{`Created a React component / widget library.`}</li>
      <li parentName="ul">{`Created a next-gen webapp server as part of our migration to the AWS cloud.`}</li>
      <li parentName="ul">{`Feature development across the consumer site, in the Cards and Photobooks product paths.`}</li>
      <li parentName="ul">{`Technologies: Typescript, Node.js, React, Java, Spring, Docker, AWS ECS`}</li>
    </ul>
    <br />
    <ImageRow imageSrcs={["/img/shutterfly/thumbnail-mobile.png", "/img/shutterfly/easel-calendar.png", "/img/shutterfly/uiguild.png", "/img/shutterfly/lighthouse-dashboard.png", "/img/shutterfly/frontendci-dashboard.png"]} mdxType="ImageRow" />
    <br />
    <br />
    <h2><a parentName="h2" {...{
        "href": "http://onlive.com/"
      }}>{`OnLive`}</a>{` (2007 - 2017)`}</h2>
    <ul>
      <li parentName="ul">{`Tech lead on the customer-facing dashboard / main menu application.`}</li>
      <li parentName="ul">{`Backend services integration.`}</li>
      <li parentName="ul">{`Game SDK development and integration.`}</li>
      <li parentName="ul">{`Technologies: C++, C, Ogre3D, Python, Java, Unreal Engine`}</li>
    </ul>
    <br />
    <ImageRow imageSrcs={["/img/onlive/onlive1.jpg", "/img/onlive/onlive2.jpg", "/img/onlive/onlive3.jpg", "/img/onlive/onlive4.jpg", "/img/onlive/onlive5.jpg"]} mdxType="ImageRow" />
    <br />
    <br />
    <h2><a parentName="h2" {...{
        "href": "https://en.wikipedia.org/wiki/Mova_(camera_system)"
      }}>{`Mova`}</a>{` (2006 - 2007)`}</h2>
    <ul>
      <li parentName="ul">{`Various dev work on the company's custom photogrammetry pipeline.`}</li>
      <li parentName="ul">{`Tools for viewing and fixing 3D animated meshes.`}</li>
      <li parentName="ul">{`Compute cluster management.`}</li>
      <li parentName="ul">{`Technologies: C++, Qt`}</li>
    </ul>
    <br />
    <ImageRow imageSrcs={["/img/mova/mova1.png", "/img/mova/mova2.png", "/img/mova/mova3.png"]} mdxType="ImageRow" />
    <br />
    <br />
    <h2>{`Centerscore (2003 - 2005)`}</h2>
    <ul>
      <li parentName="ul">{`Mobile game development`}</li>
      <li parentName="ul">{`Technologies: C++, Java, BREW and J2ME platforms`}</li>
    </ul>
    <h2>{`Cornell University '03`}</h2>
    <ul>
      <li parentName="ul">{`B.A. in Psychology with a minor in Cognitive Science`}</li>
    </ul>
    <br />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      